<template>
  <v-row>
    <v-col cols="12">
      <h2 @click="playSound()">{{ $t("user.lastplay_head") }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t("user.lastplay_head_list") }} </v-card-title>

        <v-card-actions class="pb-0 d-flex align-start">
          <v-col>
            <v-card-title style="font-size: 1em; font-weight: normal; padding: 0px; padding-bottom: 0.5rem" no-gutters>
              {{ $t("user.lastplay_head2_1") }} {{ dayLength }} {{ $t("user.lastplay_head2_2") }}
              {{ dateOld }} {{ $t("user.lastplay_head2_3") }} {{ dateNow }}</v-card-title>
            <v-row no-gutters>
              <span>
                <date-picker-input ref="dateNow" :label="$t('user.lastplay_last_checkin')" :date="dateNow"
                  @setValue="dateNow = $event" @change="updateDate" />
              </span>

              <v-btn color="primary" @click="searchByDate" class="ml-4"> {{ $t("search") }} </v-btn>
            </v-row>

            <v-row no-gutters>
              <v-col class="col-auto" style="padding: 0">
                <v-row no-gutters> {{ $t("user.lastplay_day_select") }} </v-row>
                <v-row no-gutters>
                  <v-btn color="warning" :elevated="dayLength === 3" :outlined="dayLength !== 3" @click="setDayLength(3)"
                    class="ml-0">3 {{ $t("day") }}</v-btn>
                  <v-btn color="warning" :elevated="dayLength === 7" :outlined="dayLength !== 7" @click="setDayLength(7)"
                    class="ml-4">7 {{ $t("day") }}</v-btn>
                </v-row>
              </v-col>
              <v-col class="col-12 col-sm-auto col-xl-6 mx-0 mx-md-12" style="padding: 0">
                <v-row no-gutters> {{ $t("user.lastplay_turnover") }} </v-row>
                <v-row no-gutters>
                  <v-text-field outlined dense hide-details :placeholder="$t('user.lastplay_min')" v-model="searchLowestTurnOver" />
                  <span class="d-flex align-items-center" style="padding: 0.5rem">{{ $t("user.lastplay_head2_3") }}</span>
                  <v-text-field outlined dense hide-details :placeholder="$t('user.lastplay_max')" v-model="searchHighestTurnOver" />
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-card-actions>
        <br />
        <v-card-actions>
          <v-row no-gutters>
            <v-card-title> {{ $t("user.lastplay_result") }} {{ pagination.totalItems }} </v-card-title>
            <v-spacer />

            <!-- <span class="px-3 mt-5">{{ $t('search') }}: </span>
            <div style="width: 150px" class="mt-5">
              <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
            </div> -->
          </v-row>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="itemsData"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.currentPage" :loading="loading" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }">
            <template v-slot:[`item.deposit_amount`]="{ item }">
              {{ item.deposit_amount | currency }}
            </template>
            <template v-slot:[`item.withdraw_amount`]="{ item }">
              {{ item.withdraw_amount | currency }}
            </template>
            <template v-slot:[`item.play_turn`]="{ item }">
              {{ item.play_turn | currency }}
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      menu: false,
      showDetail: false,
      toggle_exclusive: 0,
      dateOld: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateNow: moment().format('YY-MM-DD'),
      dayLength: 3,
      searchLowestTurnOver: null,
      searchHighestTurnOver: null,
      headers: [
        {
          text: this.$t("user.lastplay_user"),
          value: 'phone',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t("user.lastplay_name"),
          value: 'name',
          width: '200px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t("user.lastplay_deposit_date"),
          value: 'deposit_lastest',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t("user.lastplay_deposit_amount"),
          value: 'deposit_amount',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t("user.lastplay_withdraw_date"),
          value: 'withdraw_lastest',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t("user.lastplay_withdraw_amount"),
          value: 'withdraw_amount',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t("user.lastplay_turnover_head"),
          value: 'play_turn',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t("user.lastplay_last_play"),
          value: 'play_lastest_text',
          width: '140px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t("user.lastplay_last_play_slot"),
          value: 'slot_lastest_text',
          width: '140px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t("user.lastplay_last_play_sport"),
          value: 'sport_lastest_text',
          width: '140px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t("user.lastplay_last_play_casino"),
          value: 'casino_lastest_text',
          width: '140px',
          align: 'center',
          sortable: false,
        },
      ],
      itemsData: [],
      search: '',
      loading: false,
      dummy: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      reportRecordLimit: 2000000,
    }
  },
  async created() {
    this.addLogPage()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if ((cont && this.dummy) || (cont && !this.dummy)) {
          let params = `page=${newValue.page}&rows=${newValue.itemsPerPage}&date_now=${this.formatDateYYYY(
            this.dateNow,
          )}&day_length=${this.dayLength}`
          if (this.searchLowestTurnOver) {
            params += `&turn_min=${this.searchLowestTurnOver}`
          }
          if (this.searchHighestTurnOver) {
            params += `&turn_max=${this.searchHighestTurnOver}`
          }
          this.itemsData = []
          await this.getAffiliateLastPlayData(params)
        }
      },
      deep: true,
    },
    dateNow(newDate) {
      this.updateDate(newDate);
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
            (data = {
              ip: el.ip,
              name: 'สมาชิกเล่นล่าสุด',
              url: window.location.href,
              detail: 'สมาชิกเล่นล่าสุด',
              admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
            }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getAffiliateLastPlayData(params) {
      this.loading = true
      let res = await this.$store.dispatch('getAffiliateLastPlay', params)

      this.pagination.totalItems = res.count
      let resData = res.data

      for (let i = 0; i < resData.length; i++) {
        const play_lastest_text = resData[i].play_lastest
          ? `${this.formatDateYYYY(resData[i].play_lastest)} (${resData[i].play_day} วันที่แล้ว)`
          : '-'
        const slot_lastest_text = resData[i].slot_lastest
          ? `${this.formatDateYYYY(resData[i].slot_lastest)} (${resData[i].slot_day} วันที่แล้ว)`
          : '-'
        const sport_lastest_text = resData[i].sport_lastest
          ? `${this.formatDateYYYY(resData[i].sport_lastest)} (${resData[i].sport_day} วันที่แล้ว)`
          : '-'
        const casino_lastest_text = resData[i].casino_lastest
          ? `${this.formatDateYYYY(resData[i].casino_lastest)} (${resData[i].casino_day} วันที่แล้ว)`
          : '-'

        this.itemsData.push({
          id: resData[i].member_id,
          phone: resData[i].phone,
          name: resData[i].name,
          deposit_lastest: this.fDate(resData[i].deposit_lastest),
          deposit_amount: resData[i].deposit_amount ? resData[i].deposit_amount : 0,
          withdraw_lastest: this.fDate(resData[i].withdraw_lastest),
          withdraw_amount: resData[i].withdraw_amount ? resData[i].withdraw_amount : 0,
          play_lastest: this.fDate(resData[i].play_lastest),
          play_day: resData[i].play_day,
          play_turn: resData[i].play_turn ? resData[i].play_turn : 0,
          slot_turn: resData[i].slot_turn,
          slot_lastest: this.fDate(resData[i].slot_lastest),
          slot_day: resData[i].slot_day,
          sport_turn: resData[i].sport_turn,
          sport_lastest: this.fDate(resData[i].sport_lastest),
          sport_day: resData[i].sport_day,
          casino_turn: resData[i].casino_turn,
          casino_lastest: this.fDate(resData[i].casino_lastest),
          casino_day: resData[i].casino_day,
          play_lastest_text: play_lastest_text,
          slot_lastest_text: slot_lastest_text,
          sport_lastest_text: sport_lastest_text,
          casino_lastest_text: casino_lastest_text,
        })
      }
      this.loading = false
    },
    async searchByDate() {
      this.loading = true
      this.dummy = false
      this.itemsData = []

      let params = `page=1&rows=${this.pagination.itemsPerPage}&date_now=${this.formatDateYYYY(
        this.dateNow,
      )}&day_length=${this.dayLength}`
      if (this.searchLowestTurnOver) {
        params += `&turn_min=${this.searchLowestTurnOver}`
      }
      if (this.searchHighestTurnOver) {
        params += `&turn_max=${this.searchHighestTurnOver}`
      }
      await this.getAffiliateLastPlayData(params)
      this.pagination.page = 1
      this.loading = false
    },
    // async searchKeyword(value) {
    //   this.dummy = true
    //   this.searchData = value
    //   await this.getAffiliateLastPlayData(1, this.pagination.itemsPerPage, null, null, value)
    //   this.pagination.currentPage = 1
    // },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
    fDate(date) {
      if (date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss')
      } else {
        return '-'
      }
    },
    setDayLength(length) {
      this.dayLength = length
      this.updateDate(this.dateNow);
    },
    updateDate(newDate) {
      const formattedDate = moment(this.dateNow, 'YYYY-MM-DD').subtract(this.dayLength, 'days').format('YY-MM-DD');
      this.dateOld = formattedDate;
    },
  },
}
</script>
<style scoped>
@media (max-width: 550px) {
  .spacer {
    width: 100%;
  }
}
</style>
